import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import DialogCloseButton from '../../../../common/components/Dialog/DialogCloseButton';
import { NEW_STATUS_ID } from '../../constants';
import { ReturnLabel } from '../../rmaModels';
import { setRma } from '../../rmaSlice';
import { captureClientCodeFromLabel } from '../../utilities';

const NEW_RMA = {
    Id: null,
    Client: null,
    TrackingNumbers: [],
    AltRmaNumber: null,
    RmaNumber: null,
    ReceivedItems: [],
    ExpectedItems: [],
    Status: NEW_STATUS_ID,
    DateAdded: null,
    DateUpdated: null,
    ProcessedBy: null,
    ReceiverId: null,
};

export const RmaNotFoundDialog = React.memo(
    ({
        handleOpenDialog,
        returnLabel = null,
        showDialog = false,
        handleNewRmaDialog,
    }: {
        handleOpenDialog: (openState: boolean) => void;
        returnLabel?: ReturnLabel | null;
        showDialog?: boolean;
        handleNewRmaDialog: (openState: boolean) => void;
    }) => {
        const dispatch = useDispatch();

        return (
            <Dialog open={showDialog} fullWidth maxWidth="sm">
                <DialogTitle color="white" bgcolor="error.main" align="center">
                    <Typography fontWeight={'fontWeightMedium'} variant="h6">
                        {!!returnLabel?.reference
                            ? `NO EXPECTED ITEMS FOUND FOR THIS RETURN LABEL: ${returnLabel.reference}`
                            : 'RMA NOT FOUND'}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', mt: 1 }}>
                            <Button
                                size="large"
                                fullWidth
                                variant={!!returnLabel ? 'contained' : 'text'}
                                onClick={() => {
                                    handleOpenDialog(false);
                                    handleNewRmaDialog(true);
                                    dispatch(
                                        setRma({
                                            ...NEW_RMA,
                                            Client: captureClientCodeFromLabel(returnLabel?.reference),
                                            AltRmaNumber: returnLabel?.rma || '',
                                        })
                                    );
                                }}
                            >
                                {!!returnLabel ? `CREATE A NEW RMA FOR THIS RETURN LABEL` : 'CREATE A NEW RMA'}
                            </Button>
                            <Button
                                size="large"
                                variant="contained"
                                fullWidth
                                onClick={() => {
                                    handleOpenDialog(false);
                                }}
                                sx={{ ml: 1 }}
                            >
                                TRY ANOTHER BARCODE
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogCloseButton
                    handleClose={() => {
                        handleOpenDialog(false);
                    }}
                />
            </Dialog>
        );
    }
);
