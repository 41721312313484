import { SERIAL_QTY_LIMIT_PER_UNIT } from '../../constants';
import { ReceivedItem, ReceivedItemUnitDetail } from '../../rmaModels';
import { ScanSerialsForm } from './ScanSerialsForm';

export const SingleScanSerialsForm = ({
    receivedItem,
    receivedItemUnitDetail,
    setReceivedItemUnitDetail,
}: {
    receivedItem: ReceivedItem;
    receivedItemUnitDetail: ReceivedItemUnitDetail;
    setReceivedItemUnitDetail: (
        addingItemUnit: ReceivedItemUnitDetail | ((prevItem: ReceivedItemUnitDetail) => ReceivedItemUnitDetail)
    ) => void;
}) => {
    const serialQtyLimitReached = SERIAL_QTY_LIMIT_PER_UNIT <= receivedItemUnitDetail.Serials.length;

    const setSerials = (serial1: string, serial2: string) => {
        setReceivedItemUnitDetail((prevItem: ReceivedItemUnitDetail) => ({
            ...prevItem,
            Serials: [...prevItem.Serials, { Serial1: serial1, Serial2: serial2 }],
        }));
    };

    return (
        <ScanSerialsForm
            receivedItem={receivedItem}
            serialQtyLimitReached={serialQtyLimitReached}
            setSerials={setSerials}
        />
    );
};
