import { Box, Button } from '@mui/material';
import { red } from '@mui/material/colors';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setDialog } from '../../../notification/notificationSlice';
import useScanner from '../../../qualityControl/hooks/useScanner';
import textFieldFocus from '../../../rapidQc/utils/textFieldFocus';
import { ReceivedItem } from '../../rmaModels';
import { ScanSerialsInput } from './ScanSerialsInput';

export const ScanSerialsForm = ({
    receivedItem,
    serialQtyLimitReached,
    setSerials,
}: {
    receivedItem: ReceivedItem;
    serialQtyLimitReached: boolean;
    setSerials: (serial1: string, serial2: string) => void;
}) => {
    const dispatch = useDispatch();
    const serial1TextfieldRef = useRef<HTMLInputElement>(null);
    const serial2TextfieldRef = useRef<HTMLInputElement>(null);
    const hasSerial2Input = !!receivedItem.SerialVerify?.Serial2Alias;

    const [serial1Input, setSerial1Input] = React.useState('');
    const [serial2Input, setSerial2Input] = React.useState('');

    const passedSerialRegex = (regexString: string | null | undefined, serial: string | null | undefined) => {
        if (!!!serial) {
            return false;
        }
        if (!!!regexString) {
            return true;
        }
        if (!!regexString) {
            let regex = new RegExp(regexString);
            return regex.test(serial);
        }
        return true;
    };

    const showRegexErrorDialog = (alias: string | null | undefined) => {
        dispatch(
            setDialog({
                title: `INVALID ${alias || 'SCANNED'} NUMBER`,
                content: `This is an invalid ${alias || 'scanned'} number for this item.`,
                color: red['A100'],
            })
        );
    };

    const submitSerial = () => {
        if (!!serial1Input || !!serial2Input) {
            setSerials(serial1Input, serial2Input);
            setSerial1Input('');
            setSerial2Input('');
            textFieldFocus({ textFieldRef: serial1TextfieldRef });
        }
    };

    const handleScanningSerial1 = () => {
        if (!!serial1Input) {
            if (!passedSerialRegex(receivedItem.SerialVerify?.Serial1Regex, serial1Input)) {
                showRegexErrorDialog(receivedItem.SerialVerify?.Serial1Alias);
                setSerial1Input('');
                return;
            }
            // Add to serial list if only serial1 input
            if (!hasSerial2Input || !!serial2Input) {
                submitSerial();
                // Focus on second input if serial2 input exists
            } else if (!!!serial2Input) {
                textFieldFocus({ textFieldRef: serial2TextfieldRef });
            }
        }
    };

    const handleScanningSerial2 = () => {
        if (!passedSerialRegex(receivedItem.SerialVerify?.Serial2Regex, serial2Input)) {
            showRegexErrorDialog(receivedItem.SerialVerify?.Serial2Alias);
            setSerial2Input('');
            return;
        }
        if (!!serial2Input) {
            if (!!serial1Input) {
                submitSerial();
            } else {
                textFieldFocus({ textFieldRef: serial1TextfieldRef });
            }
        }
    };

    useScanner(serial1Input, handleScanningSerial1);
    useScanner(serial2Input, handleScanningSerial2);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ mt: 2 }}>
                <ScanSerialsInput
                    label={`SCAN ${receivedItem.SerialVerify?.Serial1Alias || 'SERIAL'} NUMBER`}
                    textfieldReference={serial1TextfieldRef}
                    currentSerial={serial1Input}
                    setCurrentSerial={setSerial1Input}
                    disabled={serialQtyLimitReached}
                    autoFocus={true}
                />
            </Box>
            {hasSerial2Input ? (
                <Box sx={{ mt: 2 }}>
                    <ScanSerialsInput
                        label={`SCAN ${receivedItem.SerialVerify?.Serial2Alias || 'SERIAL'} NUMBER`}
                        textfieldReference={serial2TextfieldRef}
                        currentSerial={serial2Input}
                        setCurrentSerial={setSerial2Input}
                        disabled={serialQtyLimitReached}
                    />
                </Box>
            ) : (
                <React.Fragment></React.Fragment>
            )}
            {!!hasSerial2Input ? (
                <Button
                    disabled={serialQtyLimitReached}
                    variant="contained"
                    size="large"
                    sx={{ mt: 2 }}
                    onClick={submitSerial}
                >
                    FINISH THIS SCAN
                </Button>
            ) : (
                <React.Fragment />
            )}
        </Box>
    );
};
