import { TextField } from '@mui/material';
import React, { Ref } from 'react';

export const ScanSerialsInput = ({
    label,
    textfieldReference,
    currentSerial,
    setCurrentSerial,
    disabled = false,
    autoFocus = false,
}: {
    label: string;
    textfieldReference: Ref<HTMLInputElement>;
    currentSerial: string | null | undefined;
    setCurrentSerial: (serialInput: string) => void;
    disabled?: boolean;
    autoFocus?: boolean;
}) => {
    return (
        <TextField
            value={currentSerial}
            inputRef={textfieldReference}
            autoFocus={autoFocus}
            disabled={disabled}
            sx={{ flex: 5 }}
            label={label}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentSerial(event.target.value);
            }}
        />
    );
};
