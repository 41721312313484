import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import { ReceivedItem, ReceivedItemSerial, ReceivedItemUnitDetail } from '../../rmaModels';
import { SingleScanSerialsForm } from './SingleScanSerialsForm';

export const ScanSerialsDisplay = ({
    receivedItem,
    receivedItemUnitDetail,
    setReceivedItemUnitDetail,
}: {
    receivedItem: ReceivedItem;
    receivedItemUnitDetail: ReceivedItemUnitDetail;
    setReceivedItemUnitDetail: (
        addingItemUnit: ReceivedItemUnitDetail | ((prevItem: ReceivedItemUnitDetail) => ReceivedItemUnitDetail)
    ) => void;
}) => {
    const handleDeleteSerialRow = (index: number) => {
        let tempSerialValues = [...receivedItemUnitDetail.Serials];
        tempSerialValues.splice(index, 1);
        setReceivedItemUnitDetail(prevItem => ({
            ...prevItem,
            Serials: tempSerialValues,
        }));
    };

    return (
        <Box>
            <SingleScanSerialsForm
                receivedItem={receivedItem}
                receivedItemUnitDetail={receivedItemUnitDetail}
                setReceivedItemUnitDetail={setReceivedItemUnitDetail}
            />
            <Box>
                {!!receivedItemUnitDetail.Serials && receivedItemUnitDetail.Serials.length > 0 ? (
                    <Typography sx={{ ml: 1, mt: 2 }} variant="body2">
                        SCANNED SERIAL:
                    </Typography>
                ) : (
                    <React.Fragment></React.Fragment>
                )}
                {receivedItemUnitDetail.Serials.map((serial: ReceivedItemSerial, index) => {
                    return (
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <TextField
                                sx={{ flex: 7, my: 0.5 }}
                                value={serial?.Serial1 || '-'}
                                variant="filled"
                                size="small"
                                label={receivedItem.SerialVerify?.Serial1Alias}
                                fullWidth
                                InputProps={{ disableUnderline: true, readOnly: true }}
                            />
                            {!!receivedItem.SerialVerify?.Serial2Alias ? (
                                <TextField
                                    sx={{ flex: 7, my: 0.5, ml: 0.5 }}
                                    value={serial?.Serial2 || '-'}
                                    variant="filled"
                                    size="small"
                                    label={receivedItem.SerialVerify?.Serial2Alias}
                                    fullWidth
                                    InputProps={{ disableUnderline: true, readOnly: true }}
                                />
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                            <Button
                                onClick={e => {
                                    handleDeleteSerialRow(index);
                                }}
                                color="error"
                                sx={{ flex: 0.1 }}
                            >
                                <DeleteForeverIcon />
                            </Button>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};
