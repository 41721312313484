import { ReceivedItem, ReceivedItemSerial } from '../../../rmaModels';
import { ScanSerialsForm } from '../ScanSerialsForm';

export const BulkScanSerialsForm = ({
    receivedItem,
    scannedSerials,
    setScannedSerials,
}: {
    receivedItem: ReceivedItem;
    scannedSerials: ReceivedItemSerial[];
    setScannedSerials: (serials: ReceivedItemSerial[]) => void;
}) => {
    const serialQtyLimitReached =
        receivedItem.ItemUnitDetails.filter(unit => !unit.ValidToSubmit).length <= scannedSerials.length;
    const setSerials = (serial1: string, serial2: string) => {
        setScannedSerials([...scannedSerials, { Serial1: serial1, Serial2: serial2 }]);
    };

    return (
        <ScanSerialsForm
            receivedItem={receivedItem}
            serialQtyLimitReached={serialQtyLimitReached}
            setSerials={setSerials}
        />
    );
};
