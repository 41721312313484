import { Box, TextField } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../app/store';
import CompanyDropdownV3 from '../../../../../common/components/dropdown/CompanyDropdownV3';
import { ReceivedItem } from '../../../rmaModels';
import { setRma } from '../../../rmaSlice';

export const CreateRmaInfoForm = ({ setAddedItems }: { setAddedItems: (items: ReceivedItem[]) => void }) => {
    const rma = useAppSelector(state => state.rma.rma);
    const dispatch = useDispatch();
    return (
        <Box sx={{ display: 'flex', pt: 2 }}>
            <CompanyDropdownV3
                value={rma?.Client || ''}
                textFieldProps={{ label: 'Client' }}
                sx={{ flex: 3, mr: 1 }}
                onChange={(event, company) => {
                    if (!!rma) dispatch(setRma({ ...rma, Client: company?.mom }));
                    setAddedItems([]);
                }}
            />
            <TextField
                disabled={!rma?.Client}
                sx={{ flex: 7 }}
                label="Scan Tracking Number (Optional)"
                value={!!rma?.TrackingNumbers && rma.TrackingNumbers.length > 0 ? rma.TrackingNumbers[0] : ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (!!rma) dispatch(setRma({ ...rma, TrackingNumbers: [event.target.value] }));
                }}
            />
        </Box>
    );
};
