import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/store';
import { setSnackbar } from '../../../notification/notificationSlice';
import { NEW_RECEIVED_ITEM } from '../../constants';
import { ExpectedItem, PairedRmaItem, ReceivedItem } from '../../rmaModels';
import { isRmaCompleted } from '../../utilities';
import { AddItemDialog } from '../AddedItems/AddItemDialog';
import { AddedItemCard } from '../AddedItems/AddedItemCard';
import { CompletedRmaInfoDisplay } from '../CompletedRmaInfoDisplay';
import { RecievedItemsSummaryDialog } from '../RecievedItemsSummary/RecievedItemsSummaryDialog';
import { ItemCard } from './ItemCard';

export const ExpectedItemsDisplay = () => {
    const dispatch = useDispatch();
    const rma = useAppSelector(state => state.rma.rma);
    const [showRecievedItemsSummaryDialog, setShowRecievedItemsSummaryDialog] = React.useState(false);
    const [showAddItemDialog, setShowAddItemDialog] = React.useState(false);
    const [addedUnexpectedItems, setAddedUnexpectedItems] = React.useState<ReceivedItem[]>([]);
    const [pairedRmaItems, setPairedRmaItems] = React.useState<PairedRmaItem[]>([]);
    const summaryButtonLabel = isRmaCompleted(rma?.Status) ? 'SHOW SUMMARY' : 'FINISH RETURN';

    const getReceivedItemForExpectedItem = (expectedItem: ExpectedItem) => {
        var receivedItem = NEW_RECEIVED_ITEM;
        if (!!rma && isRmaCompleted(rma?.Status)) {
            const foundReceivedItem = rma.ReceivedItems.find(item => item.ExpectedItemId === expectedItem.Id);
            if (!!foundReceivedItem) {
                receivedItem = foundReceivedItem;
            }
        }
        return {
            ...receivedItem,
            IsSerialized: expectedItem.IsSerialized,
            SerialVerify: expectedItem.SerialVerify,
            RmaId: expectedItem.RmaId,
            ExpectedItemId: expectedItem.Id,
            Description: expectedItem.Description,
        };
    };

    React.useEffect(() => {
        if (!!rma && isRmaCompleted(rma?.Status)) {
            setAddedUnexpectedItems(rma.ReceivedItems.filter(item => !item.ExpectedItemId));
        }
        setPairedRmaItems(() =>
            !!rma
                ? rma.ExpectedItems.map(expectedItem => ({
                      ExpectedItem: expectedItem,
                      ReceivedItem: getReceivedItemForExpectedItem(expectedItem),
                  }))
                : []
        );
    }, [rma]);

    const submitReviewHandler = () => {
        if (
            pairedRmaItems.filter(item => !item.ReceivedItem.ValidToSubmit).length > 0 ||
            addedUnexpectedItems.filter(item => !item.ValidToSubmit).length > 0
        ) {
            dispatch(
                setSnackbar({
                    content: 'Some items not are processed',
                    severity: 'error',
                })
            );
        } else {
            setShowRecievedItemsSummaryDialog(true);
        }
    };

    return (
        <React.Fragment>
            <RecievedItemsSummaryDialog
                showDialog={showRecievedItemsSummaryDialog}
                handleOpenDialog={setShowRecievedItemsSummaryDialog}
                pairedRmaItems={pairedRmaItems}
                unexpectedItems={addedUnexpectedItems}
            />
            <AddItemDialog
                showDialog={showAddItemDialog}
                handleOpenDialog={setShowAddItemDialog}
                pairedRmaItems={pairedRmaItems}
                setAddedUnexpectedItems={setAddedUnexpectedItems}
                addedUnexpectedItems={addedUnexpectedItems}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', pb: 2, pt: 1 }}>
                    <CompletedRmaInfoDisplay />
                    {pairedRmaItems.length <= 0
                        ? !isRmaCompleted(rma?.Status) && (
                              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                  <Typography variant="h6" color="warning.main">
                                      No expected items found
                                  </Typography>
                              </Box>
                          )
                        : isRmaCompleted(rma?.Status) && (
                              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                  <Typography fontWeight={'fontWeightMedium'} color="primary" variant="h5">
                                      EXPECTED ITEMS
                                  </Typography>
                              </Box>
                          )}
                    {pairedRmaItems.map((item, index) => (
                        <Box sx={{ py: 0.5 }}>
                            <ItemCard
                                pairedRmaItems={pairedRmaItems}
                                setPairedRmaItems={setPairedRmaItems}
                                index={index}
                            />
                        </Box>
                    ))}
                    {addedUnexpectedItems.length > 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Typography fontWeight={'fontWeightMedium'} color="primary" variant="h5">
                                UNEXPECTED ITEMS
                            </Typography>
                        </Box>
                    )}
                    {addedUnexpectedItems.map((item, index) => (
                        <Box sx={{ py: 0.5 }}>
                            <AddedItemCard
                                addedRmaItem={item}
                                itemIndex={index}
                                addedUnexpectedItems={addedUnexpectedItems}
                                setAddedUnexpectedItems={setAddedUnexpectedItems}
                            />
                        </Box>
                    ))}
                    {!isRmaCompleted(rma?.Status) && (
                        <Button
                            size="large"
                            fullWidth
                            onClick={() => {
                                setShowAddItemDialog(true);
                            }}
                        >
                            ADD UNEXPECTED ITEM
                        </Button>
                    )}
                    <Button
                        disabled={pairedRmaItems.length < 1 && addedUnexpectedItems.length < 1}
                        sx={{ mt: 3 }}
                        size="large"
                        fullWidth
                        variant="contained"
                        onClick={submitReviewHandler}
                    >
                        {summaryButtonLabel}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
};
