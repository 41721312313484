import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import DialogCloseButton from '../../../../../common/components/Dialog/DialogCloseButton';
import { ConfirmClosingDialog } from '../../ConfirmClosingDialog';
import { CreateRmaDisplay } from './CreateRmaDisplay';

export const CreateRmaDialog = React.memo(
    ({
        handleOpenDialog,
        showDialog = false,
    }: {
        handleOpenDialog: (openState: boolean) => void;
        showDialog?: boolean;
    }) => {
        const [confirmCloseDialog, setConfirmCloseDialog] = React.useState(false);
        return (
            <React.Fragment>
                <ConfirmClosingDialog
                    showDialog={confirmCloseDialog}
                    handleOpenDialog={setConfirmCloseDialog}
                    setPreviousDialog={handleOpenDialog}
                />
                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={showDialog}
                    PaperProps={{
                        sx: { backgroundColor: grey[100] },
                    }}
                >
                    <DialogTitle color="white" bgcolor="primary.main" align="center">
                        <Typography fontWeight={'fontWeightMedium'} variant="h5">
                            CREATE A NEW RMA
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                            <CreateRmaDisplay />
                        </Box>
                    </DialogContent>
                    <DialogCloseButton
                        handleClose={() => {
                            setConfirmCloseDialog(true);
                        }}
                    />
                </Dialog>
            </React.Fragment>
        );
    }
);
