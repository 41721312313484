import dayjs from 'dayjs';
import moment from 'moment';

export const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
    array.reduce((acc, value, index, array) => {
        (acc[predicate(value, index, array)] || (acc[predicate(value, index, array)] = [])).push(value);
        return acc;
    }, {} as { [key: string]: T[] });

export const extendNumberToTwoDecimalPlaces = (number: number) => {
    return (Math.round(number * 100) / 100).toFixed(2);
};

export const formatTypedDate = (date: dayjs.Dayjs): string | null => {
    const fixedDate = date.toString();
    const newDate = moment(fixedDate).format('MM/DD/YYYY h:mm.ss a');
    if (newDate !== 'Invalid date') return newDate;
    return null;
};

//takes in generic modal and returns object where everything is optional but at least one property is provided
export type AtLeastOne<T extends Record<string, any>> = keyof T extends infer K
    ? K extends string
        ? Pick<T, K & keyof T> & Partial<T>
        : never
    : never;

//returns string representation of an interface's property
export function propertyOf<TObj>(name: keyof TObj) {
    return name;
}

export const stringToBoolean = (input: string | undefined | null): boolean => {
    if (!input) return false;

    return input.toLowerCase().trim() === 'true';
};

//given a uri, will make browser download it
export const downloadURI = (uri: string, name: string) => {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

//convert file to base 64
export const toBase64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString() || '');
        reader.onerror = error => reject(error);
    });
};

//get content portion of a base64 encoded string
export const getContent = (base64: string) => {
    return base64?.split(',')[1];
};

//get header portion of a base64 encoded string
export const getEncodingHeaeder = (base64: string) => {
    return base64?.split(',')[0] + ',';
};
