import {
    DREAM_URL,
    GET_RECEIVERS_CONFIG_URL,
    GET_RECEIVERS_HISTORY_URL,
    GET_RECEIVERS_INBOUNDS_URL,
    GET_RECEIVERS_STOCKS_URL,
    GET_RECEIVERS_URL,
    PLAY_RECEIVERS_URL,
    POST_RECEIVERS_FORM_URL,
} from '../../app/services/api_constants';
import { baseApi } from '../../app/services/baseApi';
import { prependDreamUrl } from '../../app/services/util';
import { ConfigData } from '../receivers/receiversModels';
import { DreamResponse } from '../tracking/trackingModels';
import {
    GetReceiversRequest,
    History,
    Inbound,
    PlayReceiverPayload,
    Receiver,
    Stock,
    SubmissionForm,
} from './receiversModels';

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: ['receiversDataGrid'] });

export default apiWithTag.injectEndpoints({
    endpoints: builder => ({
        getReceiversDeprecated: builder.query<Receiver[], GetReceiversRequest>({
            query: param => ({
                url: prependDreamUrl(
                    `${GET_RECEIVERS_URL}?MomCode=${param.momCode}&StartDate=${param.startDate}&EndDate=${param.endDate}&active=${param.active}`
                ),
                method: 'GET',
                headers: {
                    RushOrderMomCode: param.momCode,
                },
            }),
            providesTags: ['receiversDataGrid'],
        }),
        getReceiversConfigDeprecated: builder.query<ConfigData, string>({
            query: momCode => ({
                url: prependDreamUrl(`${GET_RECEIVERS_CONFIG_URL}/${momCode}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: momCode,
                },
            }),
        }),
        getReceiverInboundsDeprecated: builder.query<Inbound[], string>({
            query: momCode => ({
                url: prependDreamUrl(`${GET_RECEIVERS_INBOUNDS_URL}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: momCode,
                },
            }),
        }),
        getReceiverStocksDeprecated: builder.query<Stock[], string>({
            query: momCode => ({
                url: prependDreamUrl(`${GET_RECEIVERS_STOCKS_URL}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: momCode,
                },
            }),
        }),
        getReceiversHistoryDeprecated: builder.query<History[], { momCode: string; receiverNumber: number }>({
            query: param => ({
                url: prependDreamUrl(`${GET_RECEIVERS_HISTORY_URL}/${param.receiverNumber}`),
                method: 'GET',
                headers: {
                    RushOrderMomCode: param.momCode,
                },
            }),
        }),
        playReceiverDeprecated: builder.mutation<
            DreamResponse,
            Partial<{ momCode: string; body: PlayReceiverPayload }>
        >({
            query: param => ({
                url: prependDreamUrl(`${PLAY_RECEIVERS_URL}`),
                method: 'PUT',
                body: param.body,
                headers: {
                    RushOrderMomCode: param.momCode,
                },
            }),
            invalidatesTags: ['receiversDataGrid'],
        }),
        updateReceiverDeprecated: builder.mutation<DreamResponse, Partial<{ payload: SubmissionForm }>>({
            query: param => ({
                url: prependDreamUrl(`${POST_RECEIVERS_FORM_URL}`),
                method: 'PUT',
                body: param.payload,
                headers: {
                    RushOrderMomCode: param.payload?.Client,
                    'Access-Control-Allow-Origin': DREAM_URL,
                    'Access-Control-Allow-Credentials': 'true',
                },
            }),
            invalidatesTags: ['receiversDataGrid'],
        }),
        createReceiverDeprecated: builder.mutation<DreamResponse, Partial<{ payload: SubmissionForm }>>({
            query: param => ({
                url: prependDreamUrl(`${POST_RECEIVERS_FORM_URL}`),
                method: 'POST',
                body: param.payload,
                headers: {
                    RushOrderMomCode: param.payload?.Client,
                    'Access-Control-Allow-Origin': DREAM_URL,
                    'Access-Control-Allow-Credentials': 'true',
                },
            }),
            invalidatesTags: ['receiversDataGrid'],
        }),
    }),
});
