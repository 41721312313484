import { Box, Card, CardContent, Typography } from '@mui/material';
import { useAppSelector } from '../../../app/store';
import { isRmaCompleted } from '../utilities';
import React from 'react';
import { formatDayjsDateWithoutTime } from '../../../common/utils/dateUtil';

export const CompletedRmaInfoDisplay = () => {
    const rma = useAppSelector(state => state.rma.rma);

    if (!isRmaCompleted(rma?.Status)) return <React.Fragment />;

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography fontWeight={'fontWeightMedium'} variant="h5" color="primary.main" gutterBottom>
                    PROCESS DETAILS
                </Typography>
            </Box>
            <Card>
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
                        <Box>
                            <Typography>DATE COMPLETED: {formatDayjsDateWithoutTime(rma?.DateUpdated)}</Typography>
                            <Typography>RECEIVER ID: {rma?.ReceiverId}</Typography>
                        </Box>
                        <Typography>BY: {rma?.ProcessedBy}</Typography>
                    </Box>
                </CardContent>
            </Card>
        </React.Fragment>
    );
};
