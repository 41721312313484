import {
    CLIENT_CODE_REGEX_FROM_REF_PO,
    COMPLETED_STATUS,
    DISPOSITION_OTHER_ID,
    MISSING_SERIAL_REASON_OTHER_ID,
    RMA_STATUS,
} from './constants';
import { ReceivedItemUnitDetail, Serial } from './rmaModels';

export const isRmaCompleted = (status: number | null | undefined) => {
    if (!status) {
        return false;
    }

    return RMA_STATUS[status] == COMPLETED_STATUS;
};

export const serialDisplay = (serialRecord: Serial, hasSerial2: boolean) => {
    if (hasSerial2) {
        return `${serialRecord?.Serial1 || '(none)'}, ${serialRecord?.Serial2 || '(none)'}`;
    }
    return serialRecord.Serial1;
};

export const checkItemUnitReadyToSubmit = (
    itemUnit: ReceivedItemUnitDetail,
    isSerialized: boolean,
    hasEnoughSerials?: boolean
) => {
    const hasDispositionNotesError = itemUnit.Disposition?.Id === DISPOSITION_OTHER_ID && !!!itemUnit.DispositionNotes;

    if (!!!itemUnit.Disposition) return false;
    if (hasDispositionNotesError) return false;

    if (!isSerialized) {
        return true;
    }

    // Optional serial count bypass
    if (!!hasEnoughSerials) {
        return true;
    }
    // Missing serial with no reason selected
    if (itemUnit.Serials.length === 0 && itemUnit.MissingSerialReason === null) return false;

    // Missing serial reason selected as other and no notes
    if (itemUnit.MissingSerialReason?.Id === MISSING_SERIAL_REASON_OTHER_ID && !!!itemUnit.SerialNotes) {
        return false;
    }
    return true;
};

export const captureClientCodeFromLabel = (label: string | null | undefined) => {
    if (!label) {
        return null;
    }

    const match = label.match(CLIENT_CODE_REGEX_FROM_REF_PO);
    return match ? match[0] : null;
};

export const getDetailsButtonLabel = (isRmaCompleted: boolean, isReceivedItemValidToSubmit: boolean) => {
    if (isRmaCompleted) return 'SHOW DETAILS';
    else if (isReceivedItemValidToSubmit) return 'EDIT';
    return 'PROCESS';
};
